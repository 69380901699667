body {
  --twitter-color: #50b7f5;
  --twitter-background: #e6ecf0;
}

.app {
    display: flex;
    height: 100vh;
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 10px;
}